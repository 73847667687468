<template>
    <div class="zc_dialog_box">
        <!-- 新增会员兑换码 -->
        <el-dialog title="新增兑换码" :visible.sync="dialogAdd" width="622px" @close="close" :close-on-click-modal="false">
            <div class="add_main">
                <div class="main_text center">
                    <div class="left">兑换码类型</div>
                    <div class="right">
                        <el-select v-model="form.type" style="width: 100%;" placeholder="请选择兑换码类型">
                            <el-option v-for="item in memberTypeList" :key="item.value" :label="item.label" :value="item.value" :clearable="true">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left">兑换码数量</div>
                    <div class="right">
                        <el-input v-model="form.code_number" @input="limitNum($event,'form')" placeholder="请输入兑换码数量"></el-input>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left">兑换码有效期</div>
                    <div class="right">
                        <el-date-picker v-model="time" :disabled="form.is_permanently_valid=='1'" type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </div>
                </div>
                <div class="main_text" style="margin-bottom: 0;">
                    <div class="left"><el-checkbox v-model="form.is_permanently_valid" true-label="1" false-label="2">永久有效</el-checkbox></div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogAdd = false">取 消</el-button>
                <el-button size="small" type="primary" :loading="btnLoading" @click="addMember">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 新增充值兑换码 -->
        <el-dialog title="新增兑换码" :visible.sync="dialogAddTwo" width="622px" @close="close" :close-on-click-modal="false">
            <div class="add_main">
                <div class="main_text center">
                    <div class="left">兑换码类型</div>
                    <div class="right">
                        <el-select v-model="formTwo.type" style="width: 100%;" placeholder="请选择兑换码类型" @change="changeType">
                            <el-option v-for="item in rechargeTypeList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="main_text center">
                    <div class="left">兑换码面额</div>
                    <div class="right">
                        <el-select v-model="formTwo.recharge_diamond_config_id" style="width: 100%;" placeholder="请选择兑换码面额" @change="changeConfig($event)">
                            <el-option v-for="item in quotaList" :key="item.id" :label="item.earn_number + item.earn_unit_text" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left">兑换码数量</div>
                    <div class="right">
                        <el-input v-model="formTwo.code_number" @input="limitNum($event,'formTwo')" placeholder="请输入兑换码数量"></el-input>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left">兑换码有效期</div>
                    <div class="right">
                        <el-date-picker v-model="time" :disabled="formTwo.is_permanently_valid=='1'" type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </div>
                </div>
                <div class="main_text" style="margin-bottom: 0;">
                    <div class="left"><el-checkbox v-model="formTwo.is_permanently_valid" true-label="1" false-label="2">永久有效</el-checkbox></div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogAddTwo = false">取 消</el-button>
                <el-button size="small" type="primary" :loading="btnLoading" @click="addRecharge">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 新增训练兑换码 -->
        <el-dialog title="新增兑换码" :visible.sync="dialogAddThree" width="622px" @close="close" :close-on-click-modal="false">
            <div class="add_main">
                <div class="main_text center">
                    <div class="left">兑换码类型</div>
                    <div class="right">
                        <el-select v-model="formThree.type" style="width: 100%;" placeholder="请选择兑换码类型">
                            <el-option v-for="item in trainTypeList" :key="item.value" :label="item.label" :value="item.value" :clearable="true">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left">兑换码数量</div>
                    <div class="right">
                        <el-input v-model="formThree.code_number" @input="limitNum($event,'formThree')" placeholder="请输入兑换码数量"></el-input>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left">兑换码有效期</div>
                    <div class="right">
                        <el-date-picker v-model="time" :disabled="formThree.is_permanently_valid=='1'" type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </div>
                </div>
                <div class="main_text" style="margin-bottom: 0;">
                    <div class="left"><el-checkbox v-model="formThree.is_permanently_valid" true-label="1" false-label="2">永久有效</el-checkbox></div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogAddThree = false">取 消</el-button>
                <el-button size="small" type="primary" :loading="btnLoading" @click="addTrain">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        memberTypeList: Array,
        rechargeTypeList: Array,
        trainTypeList: Array,
    },
    data() {
        return {
            dialogAdd: false,
            dialogAddTwo: false,
            btnLoading: false,
            dialogAddThree: false,
            form: {
                type: '',
                code_number: '',
                start_time: '',
                end_time: '',
                is_permanently_valid: '2',
            },
            formTwo: {
                type: '',
                recharge_diamond_config_id: '',
                earn_number: '',
                earn_unit: '',
                code_number: '',
                start_time: '',
                end_time: '',
                is_permanently_valid: '2',
            },
            formThree: {
                type: '',
                code_number: '',
                start_time: '',
                end_time: '',
                is_permanently_valid: '2',
            },
            time: '',
            quotaList: [],
        };
    },
    created() {

    },
    methods: {
        openAdd() {
            this.dialogAdd = true
        },
        openAddTwo() {
            this.dialogAddTwo = true
        },
        openAddThree() {
            this.dialogAddThree = true
        },
        // 添加会员码
        addMember() {
            if (this.time == null || this.time == '') {
                this.form.start_time = ''
                this.form.end_time = ''
            } else {
                this.form.start_time = this.time[0]
                this.form.end_time = this.time[1]
            }
            if (this.form.type == '') {
                this.$errMsg('请选择兑换码类型')
                return
            }
            if (this.form.code_number == '') {
                this.$errMsg('请输入兑换码数量')
                return
            }
            if (this.form.is_permanently_valid == '2' && this.form.start_time == '') {
                this.$errMsg('请选择兑换码有效期')
                return
            }
            let obj = {
                type: this.form.type,
                code_number: this.form.code_number,
                start_time: this.form.start_time,
                end_time: this.form.end_time,
                is_permanently_valid: this.form.is_permanently_valid,
            }
            this.btnLoading = true;
            this.$setupApi.addMemberCode(obj).then(res => {
                this.btnLoading = false;
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.dialogAdd = false;
                    this.$emit('getMemberList')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 添加充值码
        addRecharge() {
            if (this.time == null || this.time == '') {
                this.formTwo.start_time = ''
                this.formTwo.end_time = ''
            } else {
                this.formTwo.start_time = this.time[0]
                this.formTwo.end_time = this.time[1]
            }
            if (this.formTwo.type == '') {
                this.$errMsg('请选择兑换码类型')
                return
            }
            if (this.formTwo.recharge_diamond_config_id == '') {
                this.$errMsg('请选择兑换码面额')
                return
            }
            if (this.formTwo.code_number == '') {
                this.$errMsg('请输入兑换码数量')
                return
            }
            if (this.formTwo.is_permanently_valid == '2' && this.formTwo.start_time == '') {
                this.$errMsg('请选择兑换码有效期')
                return
            }
            let obj = {
                type: this.formTwo.type,
                code_number: this.formTwo.code_number,
                start_time: this.formTwo.start_time,
                end_time: this.formTwo.end_time,
                is_permanently_valid: this.formTwo.is_permanently_valid,
                recharge_diamond_config_id: this.formTwo.recharge_diamond_config_id,
                earn_number: this.formTwo.earn_number,
                earn_unit: this.formTwo.earn_unit,
            }
            this.btnLoading = true;
            this.$setupApi.addRechargeCode(obj).then(res => {
                this.btnLoading = false;
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.dialogAddTwo = false;
                    this.$emit('getRechargeList')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 添加训练码
        addTrain() {
            if (this.time == null || this.time == '') {
                this.formThree.start_time = ''
                this.formThree.end_time = ''
            } else {
                this.formThree.start_time = this.time[0]
                this.formThree.end_time = this.time[1]
            }
            if (this.formThree.type == '') {
                this.$errMsg('请选择兑换码类型')
                return
            }
            if (this.formThree.code_number == '') {
                this.$errMsg('请输入兑换码数量')
                return
            }
            if (this.formThree.is_permanently_valid == '2' && this.formThree.start_time == '') {
                this.$errMsg('请选择兑换码有效期')
                return
            }
            let obj = {
                type: this.formThree.type,
                code_number: this.formThree.code_number,
                start_time: this.formThree.start_time,
                end_time: this.formThree.end_time,
                is_permanently_valid: this.formThree.is_permanently_valid,
            }
            this.btnLoading = true;
            this.$setupApi.addTrainCode(obj).then(res => {
                this.btnLoading = false;
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.dialogAddThree = false;
                    this.$emit('getTrainList')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        changeConfig(val) {
            var params = {}
            params = this.quotaList.find(function (i) {
                return i.id === val
            });
            this.formTwo.earn_number = params.earn_number;
            this.formTwo.earn_unit = params.earn_unit;
        },
        changeType() {
            this.formTwo.recharge_diamond_config_id = ''
            this.getDiamondConfig()
        },
        // 获取面额
        getDiamondConfig() {
            let obj = {
                type: this.formTwo.type,
                page: 1,
                limit: 10000,
                status: 1,
            }
            this.$setupApi.getDiamondConfig(obj).then(res => {
                if (res.code == 1000) {
                    this.quotaList = res.result.data
                }
            })
        },
        close() {
            this.form = {
                type: '',
                code_number: '',
                start_time: '',
                end_time: '',
                is_permanently_valid: '2',
            }
            this.formTwo = {
                type: '',
                recharge_diamond_config_id: '',
                earn_number: '',
                earn_unit: '',
                code_number: '',
                start_time: '',
                end_time: '',
                is_permanently_valid: '2',
            }
            this.formThree = {
                type: '',
                code_number: '',
                start_time: '',
                end_time: '',
                is_permanently_valid: '2',
            }
            this.time = ''
            this.btnLoading = false;
        },
        // 输入框限制
        limitNum(e, type) {
            if (type == 'form') {
                this.form.code_number = e.replace(/[^0-9]/g, '')
            } else if (type == 'formTwo') {
                this.formTwo.code_number = e.replace(/[^0-9]/g, '')
            } else {
                this.formThree.code_number = e.replace(/[^0-9]/g, '')
            }
        },
    },
};
</script>

<style scoped lang="scss">
.add_main {
    width: 100%;

    .center {
        align-items: center;
    }
    .main_text {
        width: 100%;
        margin-bottom: 20px;
        display: flex;

        .left {
            width: 100px;
            text-align: right;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 40px;
            margin-right: 20px;
        }

        .right {
            width: 360px;

            .el-input {
                width: 100%;
            }

            .el-date-editor {
                width: 100%;
            }
        }
    }
}
</style>
