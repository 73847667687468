<template>
    <div>
        <el-dialog title="订阅详情" :visible.sync="dialogTableVisible" width="962px" @close="close" :close-on-click-modal="false">
            <div class="zc_dialog_box">
                <div class="table_wrap">
                    <el-table key="table" :data="list" v-loading="loading" max-height="535px" header-cell-class-name="table-header" cell-class-name="cell-class">
                        <el-table-column property="order" label="序号" align="center" width="50"></el-table-column>
                        <el-table-column property="code" label="兑换码" width="160"></el-table-column>
                        <el-table-column property="redeem_name" label="兑换名称"></el-table-column>
                        <el-table-column property="mobile" label="兑换账号" width="120"></el-table-column>
                        <el-table-column property="resource_text" label="兑换码来源"></el-table-column>
                        <el-table-column property="reedem_time" label="兑换时间" width="180"></el-table-column>
                        <el-table-column property="is_used" label="使用状态" width="90">
                            <template slot-scope="scope">
                                <span>{{scope.row.is_used=='1'?'已使用':'待使用'}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="90">
                            <template slot-scope="scope">
                                <el-switch :key="scope.row.id" v-model="scope.row.status" :active-value="1" :inactive-value="2" @change="changeSwitch($event,scope.row)"></el-switch>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="page_box pr20">
                    <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
export default {
    components: { PagingPage },
    data() {
        return {
            page: 1,
            limit: 10,
            total: 0,
            dialogTableVisible: false,
            list: [],
            loading: false,
            type: '',
        };
    },
    created() {

    },
    methods: {
        open(id, type) {
            // type 1 会员码   2充值码  3训练码
            this.type = type;
            this.id = id;
            this.dialogTableVisible = true;
            if (type == '1') {
                this.getMemberList(id)
            } else if (type == '2') {
                this.getRechargeList(id)
            } else {
                this.getTrainList(id)
            }
        },
        // 获取充值码列表
        getRechargeList(id) {
            let obj = {
                page: this.page,
                limit: this.limit,
            }
            this.loading = true
            this.$setupApi.rechargeCodeInfo(obj, id).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = res.result.data
                    this.total = res.result.total
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 获取会员码列表
        getMemberList(id) {
            let obj = {
                page: this.page,
                limit: this.limit,
            }
            this.loading = true
            this.$setupApi.getMemberList(obj, id).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = res.result.data
                    this.total = res.result.total
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 获取训练码列表
        getTrainList(id) {
            let obj = {
                page: this.page,
                limit: this.limit,
            }
            this.loading = true
            this.$setupApi.getTrainList(obj, id).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = res.result.data
                    this.total = res.result.total
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 开关状态修改
        changeSwitch(status, val) {
            let obj = {
                status: status,
                id: val.id
            }
            if (this.type == '1') {
                // 会员码开关
                this.$setupApi.memberDetailStatus(obj).then(res => {
                    if (res.code == 1000) {
                        this.$succMsg('状态修改成功')
                    } else {
                        this.$errMsg(res.message)
                    }
                })
            } else if (this.type == '2') {
                // 充值码开关
                this.$setupApi.rechargeDetailStatus(obj).then(res => {
                    if (res.code == 1000) {
                        this.$succMsg('状态修改成功')
                    } else {
                        this.$errMsg(res.message)
                    }
                })
            } else {
                // 训练码开关
                this.$setupApi.trainDetailStatus(obj).then(res => {
                    if (res.code == 1000) {
                        this.$succMsg('状态修改成功')
                    } else {
                        this.$errMsg(res.message)
                    }
                })

            }
        },
        sizeChangeBtn(val) {
            this.page = 1;
            this.limit = val
            if (this.type == '1') {
                this.getMemberList(this.id)
            } else if (this.type == '2') {
                this.getRechargeList(this.id)
            } else {
                this.getTrainList(this.id)
            }
        },
        currentChangeBtn(val) {
            this.page = val;
            if (this.type == '1') {
                this.getMemberList(this.id)
            } else if (this.type == '2') {
                this.getRechargeList(this.id)
            } else {
                this.getTrainList(this.id)
            }
        },
        close() {
            this.page = 1;
            this.limit = 10;
            this.total = 0;
            this.list = [];
            this.loading = false;
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
    padding: 0 20px 30px;
}
</style>
