<template>
    <div>
        <el-dialog title="新增" :visible.sync="dialogEdit" width="622px" :close-on-click-modal="false">
            <div class="add_main">
                <div class="main_flex">
                    <div class="input w188">
                        <el-input style="width: 128px;" v-model="info.diamond_number" oninput="this.value=this.value.replace(/[^0-9.]/g,'')" placeholder="请输入"></el-input>
                        <div class="box">钻</div>
                    </div>
                    <span class="line">-</span>
                    <div class="input w214">
                        <el-input style="width: 154px;" v-model="info.earn_number" oninput="this.value=this.value.replace(/[^0-9.]/g,'')" placeholder="请输入"></el-input>
                        <div class="box">{{info.earn_unit_text}}</div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogEdit = false">取 消</el-button>
                <el-button size="small" type="primary" :loading="btnLoading" @click="confirm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialogEdit: false,
            btnLoading: false,
            info: {},
        };
    },
    methods: {
        open(val) {
            this.dialogEdit = true
            this.info = JSON.parse(JSON.stringify(val))
        },
        confirm() {
            if (this.info.diamond_number == '') {
                this.$errMsg('请输入钻石数')
                return
            }
            if (this.info.earn_number == '') {
                this.$errMsg('请输入充值数量')
                return
            }
            let obj = {
                diamond_number: this.info.diamond_number,
                earn_number: this.info.earn_number
            }
            this.btnLoading = true
            this.$setupApi.diamondEdit(obj, this.info.id).then(res => {
                this.btnLoading = false
                if (res.code == 1000) {
                    this.dialogEdit = false;
                    this.$succMsg(res.message)
                    this.$emit('getList', '')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
    },
};
</script>

<style scoped lang="scss">
.add_main {
    width: 100%;
    .main_flex {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        margin-left: 66px;
        .input {
            display: flex;
            align-items: center;
            .box {
                width: 60px;
                height: 40px;
                background: #2e4bf2;
                border-radius: 0px 4px 4px 0px;
                margin-left: -2px;
                z-index: 100;
                font-size: 14px;
                color: #ffffff;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .line {
            padding: 12px;
        }
        .w188 {
            width: 188px;
        }
        .w214 {
            width: 214px;
        }
    }
}
</style>
