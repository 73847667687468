<template>
    <div class='recharge_config'>
        <div class="vue_title">充值配置</div>
        <div class="tabs">
            <div class="tabs-item" :class="{active:item.value==tabIndex}" v-for="item in tabs" :key="item.value" @click="changeTabs(item.value)">{{ item.label }}</div>
        </div>
        <member-list v-if="tabIndex=='1'" :cardTypeList="cardTypeList" :dateValidList="dateValidList"></member-list>
        <recharge-list v-if="tabIndex=='2'" :typeList="typeList" :earnUnit="earnUnit"></recharge-list>
        <redemption-code-list v-if="tabIndex=='3'" :earnUnit="earnUnit" :memberTypeList="memberTypeList" :timeSelectList="timeSelectList" :rechargeTypeList="rechargeTypeList" :trainTypeList="trainTypeList"></redemption-code-list>
    </div>
</template>

<script>

import MemberList from './memberList.vue'   //会员列表
import RechargeList from './rechargeList.vue'   //充值列表
import RedemptionCodeList from './redemptionCodeList.vue'  //兑换码列表
export default {
    components: { MemberList, RechargeList, RedemptionCodeList },
    props: {},
    data() {
        return {
            tabs: [
                {
                    label: '会员设置',
                    value: '1',
                },
                {
                    label: '钻石充值',
                    value: '2',
                },
                {
                    label: '兑换码',
                    value: '3',
                },
            ],
            tabIndex: '1',
            typeList: [],
            earnUnit: [],   //单位
            memberTypeList: [],
            timeSelectList: [],   //时间段
            rechargeTypeList: [],
            trainTypeList: [],
            cardTypeList: [],
            dateValidList: [],
        }
    },
    created() {
        // 会员设置添加参数
        this.getMemberParams()
        //钻石充值类型   单位
        this.getDiamondParams()
        // 兑换码  会员兑换码参数
        this.getMemberCodeParams()
        // 兑换码  充值兑换码参数
        this.getRechargeCodeParams()
        //兑换码  训练兑换码参数
        this.getTrainCodeParams()
    },
    methods: {
        changeTabs(val) {
            this.tabIndex = val
        },
        getMemberParams() {
            this.$setupApi.memberConfig().then(res => {
                if (res.code == 1000) {
                    this.cardTypeList = res.result.card_type
                    this.dateValidList = res.result.date_valid
                }
            })
        },
        //钻石充值类型
        getDiamondParams() {
            this.$setupApi.getParams().then(res => {
                if (res.code == 1000) {
                    this.typeList = res.result.type
                    this.earnUnit = res.result.earn_unit
                }
            })
        },
        // 会员兑换码类型
        getMemberCodeParams() {
            this.$setupApi.getMemberParams().then(res => {
                if (res.code == 1000) {
                    this.memberTypeList = res.result.type
                    this.timeSelectList = res.result.time_select
                }
            })
        },
        // 充值兑换码类型
        getRechargeCodeParams() {
            this.$setupApi.getRechargeParams().then(res => {
                if (res.code == 1000) {
                    this.rechargeTypeList = res.result.type
                }
            })
        },
        // 训练兑换码参类型
        getTrainCodeParams() {
            this.$setupApi.getTrainParams().then(res => {
                if (res.code == 1000) {
                    this.trainTypeList = res.result.type
                }
            })
        },
    },
}
</script>

<style lang='scss' scoped>
@import "./index.scss";
.height {
    height: calc(100vh - 270px);
}
</style>