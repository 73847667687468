<template>
    <div class="zc_dialog_box">
        <el-dialog title="新增" :visible.sync="dialogAdd" width="622px" @close="close" :close-on-click-modal="false">
            <div class="add_main" v-loading="loading">
                <div class="main_text">
                    <div class="left">选择类型</div>
                    <div class="right">
                        <el-select v-model="type" placeholder="请选择" @change="changeType">
                            <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="main_flex" v-for="(item,index) in addList" :key="index">
                    <div class="input w188">
                        <el-input style="width: 128px;" v-model="item.diamond_number" oninput="this.value=this.value.replace(/[^0-9.]/g,'')" placeholder="请输入"></el-input>
                        <div class="box">钻</div>
                    </div>
                    <span class="line">-</span>
                    <div class="input w214">
                        <el-input style="width: 154px;" v-model="item.earn_number" oninput="this.value=this.value.replace(/[^0-9.]/g,'')" placeholder="请输入"></el-input>
                        <el-dropdown v-if="type=='4'" @command="handleCommand($event,index)">
                            <el-button class="box" type="primary">
                                {{item.earn_unit=='3'?'秒':'分'}}<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="3">秒</el-dropdown-item>
                                <el-dropdown-item command="2">分</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <div class="box" v-if="type=='15'">元</div>
                        <div class="box" v-if="type=='11'">分</div>
                        <div class="box" v-if="type=='9'">次</div>
                        <div class="box" v-if="type=='12'">次</div>
                    </div>
                    <div class="del" @click="del(index)" v-if="index>0">
                        <img src="@/assets/images/img/del.png" alt="">
                    </div>
                </div>
                <div class="add-btn">
                    <span @click="add"> + 新增</span>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogAdd = false">取 消</el-button>
                <el-button size="small" type="primary" :loading="btnLoading" @click="confirm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {

    },
    data() {
        return {
            dialogAdd: false,
            btnLoading: false,
            loading: false,
            typeList: [
                {
                    value: "15",
                    label: "钻石充值"
                },
                {
                    value: "4",
                    label: "视频时长充值"
                },
                {
                    value: "11",
                    label: "音频时长充值"
                },
                {
                    value: "9",
                    label: "AI绘画次数充值"
                },
                {
                    value: "12",
                    label: "AI漫画次数充值"
                }
            ],
            type: "15",
            earnUnitList: [
                {
                    value: "1",
                    label: "元"
                },
                {
                    value: "2",
                    label: "分"
                },
                {
                    value: "3",
                    label: "秒"
                },
                {
                    value: "4",
                    label: "次"
                }
            ],
            addList: [
                {
                    diamond_number: '',
                    earn_number: '',
                    earn_unit: '1'
                }
            ],
            earn_unit: '',  //单位
        };
    },
    created() {

    },
    methods: {
        changeType() {
            this.addList = []
            this.add()
        },
        add() {
            if (this.type == '15') {
                this.earn_unit = '1'
            } else if (this.type == '4') {
                this.earn_unit = '2'
            } else if (this.type == '11') {
                this.earn_unit = '2'
            } else if (this.type == '9') {
                this.earn_unit = '4'
            } else if (this.type == '12') {
                this.earn_unit = '4'
            }
            this.addList.push({
                diamond_number: '',
                earn_number: '',
                earn_unit: this.earn_unit,
            })
        },
        handleCommand(val, index) {
            this.addList[index].earn_unit = val
        },

        confirm() {
            if (this.addList.some(ele => ele.diamond_number == '')) {
                this.$errMsg('请输入钻石数')
                return
            }
            if (this.addList.some(ele => ele.earn_number == '')) {
                this.$errMsg('请输入充值数')
                return
            }
            let obj = {
                type: this.type,
                data: this.addList
            }
            this.btnLoading = true
            this.$setupApi.diamondAdd(obj).then(res => {
                this.btnLoading = false
                if (res.code == 1000) {
                    this.dialogAdd = false;
                    this.$succMsg(res.message)
                    this.$emit('getList', '')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        close() {
            this.type = "15";
            this.addList = [
                {
                    diamond_number: '',
                    earn_number: '',
                    earn_unit: '1'
                }
            ]
        },
        open() {
            this.dialogAdd = true
        },
        // 删除
        del(index) {
            this.addList.splice(index, 1)
        },
    },
};
</script>

<style scoped lang="scss">
.add_main {
    width: 100%;

    .main_text {
        width: 100%;
        margin-bottom: 20px;
        display: flex;

        .left {
            width: 120px;
            text-align: right;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 40px;
            margin-right: 20px;
        }

        .right {
            width: 360px;
            display: flex;
            align-items: center;

            .el-input {
                width: 100%;
            }
            .el-select {
                width: 100%;
            }

            .box {
                width: 60px;
                height: 40px;
                background: #2e4bf2;
                border-radius: 0px 4px 4px 0px;
                margin-left: -2px;
                z-index: 100;
                font-size: 14px;
                color: #ffffff;
                text-align: center;
                line-height: 40px;
            }
        }
    }
    .main_flex {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        margin-left: 66px;
        .input {
            display: flex;
            align-items: center;
            .box {
                width: 60px;
                height: 40px;
                background: #2e4bf2;
                border-radius: 0px 4px 4px 0px;
                margin-left: -2px;
                z-index: 100;
                font-size: 14px;
                color: #ffffff;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .del {
            width: 36px;
            height: 36px;
            cursor: pointer;
            margin-left: 16px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .line {
            padding: 12px;
        }
        .w188 {
            width: 188px;
        }
        .w214 {
            width: 214px;
        }
    }
    .add-btn {
        padding-left: 64px;
        color: #2e4bf2;
        font-size: 15px;
        cursor: pointer;
    }
}
</style>
