<template>
    <div class="rechargeList">
        <div class="main">
            <div class="search-c">
                <el-button size="small" class="btnBgColor_blue_empty_no mr20" @click="add">+ 新增</el-button>
                <div class="input-box mr20">
                    <el-select v-model="type" size="small" placeholder="请选择充值类型" @change="search" :clearable="true">
                        <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-box mr20">
                    <el-input size="small" v-model="diamond_number" @input="limitNum" @keyup.enter.native="search" :clearable="true" prefix-icon="el-icon-search" placeholder="请输入钻石数"></el-input>
                </div>
            </div>
            <div class="table_wrap height">
                <el-table key="two" :data="list" v-loading="loading" style="width: 100%" height="100%" header-cell-class-name="table-header" cell-class-name="cell-class">
                    <template slot="empty">
                        <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                    </template>
                    <el-table-column prop="type" align="center" label="归类属性">
                        <template slot-scope="scope">
                            <p v-for="item in typeList" :key="item.value">
                                <span v-if="item.value==scope.row.type">{{item.label}}</span>
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="diamond_number" label="钻石数">
                    </el-table-column>
                    <el-table-column label="数值（单位）">
                        <template slot-scope="scope">
                            <p v-for="item in earnUnit" :key="item.value">
                                <span v-if="item.value==scope.row.earn_unit">{{scope.row.earn_number}}{{item.label}}</span>
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <el-switch :key="scope.row.id" v-model="scope.row.status" :active-value="1" :inactive-value="2" @change="changeSwitch($event,scope.row)"></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间">
                    </el-table-column>
                    <el-table-column prop="update_time" label="更新时间">
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" width="100">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="edit(scope.row)">
                                编辑
                            </el-button>
                            <el-button type="text" size="small" @click="del(scope.row)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="page_box pr20">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
        <!-- 删除 -->
        <point-out ref='dialogTip' @close='closeBtn' type='accountDel' dialogTitle="提示" dialogWidth='400px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">删除后该套餐不会再显示在云川平台上，您确定要删除该套餐吗？</div>
            </div>
        </point-out>
        <add-recharge ref='addrecharge' @getList="getList"></add-recharge>
        <edit-recharge ref='editrecharge' @getList="getList"></edit-recharge>
    </div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import PointOut from '@/components/point_out/point_out.vue'   //删除弹窗
import AddRecharge from '../components/addRecharge.vue'  //添加弹窗
import EditRecharge from '../components/editRecharge.vue'  //编辑弹窗
export default {
    components: { EmptyData, PagingPage, PointOut, AddRecharge, EditRecharge },
    props: {
        typeList: Array,
        earnUnit: Array,
    },
    data() {
        return {
            page: 1,
            limit: 10,
            total: 0,
            loading: false,
            type: '',
            diamond_number: '',
            list: [],
            id: '',
        };
    },
    created() {
        this.getList()
    },
    methods: {
        add() {
            this.$refs.addrecharge.open()
        },
        edit(val) {
            this.$refs.editrecharge.open(val)
        },
        getList() {
            let obj = {
                page: this.page,
                limit: this.limit,
                diamond_number: this.diamond_number,
                type: this.type,
            }
            this.loading = true
            this.$setupApi.diamondList(obj).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = res.result.data
                    this.total = res.result.total
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        search() {
            this.page = 1;
            this.getList()
        },
        // 删除
        closeBtn() {
            console.log('删除')
            this.$setupApi.diamondDel(this.id).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.getList()
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 删除提示
        del(val) {
            this.id = val.id;
            this.$refs.dialogTip.openDialogBtn()
        },
        // 开关状态修改
        changeSwitch(status, val) {
            let obj = {
                status: status,
                id: val.id
            }
            this.$setupApi.diamondConfigStatus(obj).then(res => {
                if (res.code == 1000) {
                    this.$succMsg('状态修改成功')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 输入框限制
        limitNum(e) {
            this.diamond_number = e.replace(/[^0-9.]/g, '')
        },
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList();
        },
        currentChangeBtn(val) {
            this.page = val;
            this.getList();
        },
    },

};
</script>

<style scoped lang="scss">
@import "./index.scss";
.height {
    height: calc(100vh - 320px);
}
</style>
