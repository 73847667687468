<template>
    <div class="rechargeList">
        <div class="main" style="padding-top: 10px;">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="会员兑换码" name="1"></el-tab-pane>
                <el-tab-pane label="充值兑换码" name="2"></el-tab-pane>
                <el-tab-pane label="训练兑换码" name="3"></el-tab-pane>
            </el-tabs>
            <div class="search-c">
                <el-button size="small" class="btnBgColor_blue_empty_no mr20" @click="addCode">+ 新增</el-button>
                <div class="input-box mr20" v-if="activeName=='1'">
                    <el-select v-model="memberType" size="small" placeholder="选择兑换码类型" @change="search" :clearable="true">
                        <el-option v-for="item in memberTypeList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-box mr20" v-if="activeName=='2'">
                    <el-select v-model="rechargeType" size="small" placeholder="选择兑换码类型" @change="search" :clearable="true">
                        <el-option v-for="item in rechargeTypeList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-box mr20" v-if="activeName=='3'">
                    <el-select v-model="trainType" size="small" placeholder="选择兑换码类型" @change="search" :clearable="true">
                        <el-option v-for="item in trainTypeList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-box mr20">
                    <el-select v-model="time_select" size="small" placeholder="有效时间段">
                        <el-option v-for="item in timeSelectList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <el-date-picker size="small" v-model="time" type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" value-format="yyyy-MM-dd" @change="search" :clearable="true">
                </el-date-picker>
                <el-button class="leading-out" size="small" @click="listExport">导出</el-button>
            </div>
            <div class="table_wrap height">
                <el-table :key="activeName" :data="list" v-loading="loading" style="width: 100%" height="100%" header-cell-class-name="table-header" cell-class-name="cell-class" @selection-change="handleSelectionChange">
                    <template slot="empty">
                        <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                    </template>
                    <el-table-column type="selection" align="center" width="55">
                    </el-table-column>
                    <el-table-column prop="type" label="兑换码类型" v-if="activeName=='1'">
                        <template slot-scope="scope">
                            <p v-for="item in memberTypeList" :key="item.value">
                                <span v-if="item.value==scope.row.type">{{item.label}}</span>
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="type" label="兑换码类型" v-if="activeName=='2'">
                        <template slot-scope="scope">
                            <p v-for="item in rechargeTypeList" :key="item.value">
                                <span v-if="item.value==scope.row.type">{{item.label}}</span>
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="type" label="兑换码类型" v-if="activeName=='3'">
                        <template slot-scope="scope">
                            <p v-for="item in trainTypeList" :key="item.value">
                                <span v-if="item.value==scope.row.type">{{item.label}}</span>
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="user.nickname" label="操作人">
                    </el-table-column>
                    <el-table-column prop="user.mobile" label="操作账号">
                    </el-table-column>
                    <el-table-column label="兑换面额" v-if="activeName=='2'">
                        <template slot-scope="scope">
                            <p v-for="item in earnUnit" :key="item.value">
                                <span v-if="item.value==scope.row.earn_unit">{{scope.row.earn_number}}{{item.label}}</span>
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="code_number" label="兑换码数量">
                    </el-table-column>
                    <el-table-column prop="use_number" label="已使用数量">
                    </el-table-column>
                    <el-table-column prop="start_time" label="生效时间" width="170">
                        <template slot-scope="scope">
                            {{scope.row.is_permanently_valid==1?'永久有效':scope.row.start_time}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="end_time" label="失效时间" width="170">
                        <template slot-scope="scope">
                            {{scope.row.is_permanently_valid==1?'永久有效':scope.row.end_time}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间" width="170">
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" width="100">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="openInfo(scope.row.id)">
                                明细
                            </el-button>
                            <el-button type="text" size="small" @click="infoExport(scope.row.id)">
                                导出
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="page_box pr20">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
        <add-redemption-code ref="addRedemptionCode" :memberTypeList="memberTypeList" :rechargeTypeList="rechargeTypeList" :trainTypeList="trainTypeList" @getTrainList="getTrainList" @getMemberList="getMemberList" @getRechargeList="getRechargeList"></add-redemption-code>
        <code-detailed ref="codedetailed"></code-detailed>
    </div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import AddRedemptionCode from '../components/addRedemptionCode.vue' //新增兑换码
import CodeDetailed from '../components/codeDetailed.vue' //兑换码明细
export default {
    components: { EmptyData, AddRedemptionCode, PagingPage, CodeDetailed },
    props: {
        earnUnit: Array,
        memberTypeList: Array,
        rechargeTypeList: Array,
        trainTypeList: Array,
        timeSelectList: Array,
    },
    data() {
        return {
            activeName: '1',
            page: 1,
            limit: 10,
            total: 0,
            list: [],
            loading: false,
            time_select: 'valid_time',
            memberType: '',
            rechargeType: '',
            trainType: '',
            time: '',
            started: '',
            ended: '',
            multipleSelection: []
        };
    },
    created() {
        this.getMemberList()
    },
    methods: {
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 列表导出
        listExport() {
            let arr = []
            this.multipleSelection.forEach(ele => {
                arr.push(ele.id)
            })
            var obj = {
                type: this.activeName == '1' ? this.memberType : this.activeName == '2' ? this.rechargeType : this.trainType,
                time_select: this.started ? this.time_select : '',
                started: this.started,
                ended: this.ended,
                ids: arr.toString()
            }
            if (this.activeName == '1') {
                this.$setupApi.getMemberExport(obj).then(res => {
                    return
                })
            } else if (this.activeName == '2') {
                this.$setupApi.getRechargeExport(obj).then(res => {
                    return
                })
            } else {
                this.$setupApi.getTrainExport(obj).then(res => {
                    return
                })
            }
        },

        // 明细导出
        infoExport(id) {
            if (this.activeName == '1') {
                this.$setupApi.getMemberInfoExport(id).then(res => {
                    return
                })
            } else if (this.activeName == '2') {
                this.$setupApi.getRechargeInfoExport(id).then(res => {
                    return
                })
            } else {
                this.$setupApi.getTrainInfoExport(id).then(res => {
                    return
                })
            }
        },

        openInfo(id) {
            this.$refs.codedetailed.open(id, this.activeName)
        },
        // 会员码
        getMemberList() {
            let obj = {
                page: this.page,
                limit: this.limit,
                type: this.memberType,
                time_select: this.started ? this.time_select : '',
                started: this.started,
                ended: this.ended,
            }
            this.loading = true
            this.$setupApi.redeemCodeMember(obj).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = res.result.data
                    this.total = res.result.total
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 充值码
        getRechargeList() {
            let obj = {
                page: this.page,
                limit: this.limit,
                type: this.rechargeType,
                time_select: this.started ? this.time_select : '',
                started: this.started,
                ended: this.ended,
            }
            this.loading = true
            this.$setupApi.redeemCodeRecharge(obj).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = res.result.data
                    this.total = res.result.total
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 训练码
        getTrainList() {
            let obj = {
                page: this.page,
                limit: this.limit,
                type: this.trainType,
                time_select: this.started ? this.time_select : '',
                started: this.started,
                ended: this.ended,
            }
            this.loading = true
            this.$setupApi.redeemCodeTrain(obj).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = res.result.data
                    this.total = res.result.total
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 搜索
        search() {
            this.page = 1;
            if (this.time == null) {
                this.started = ''
                this.ended = ''
            } else {
                this.started = this.time[0]
                this.ended = this.time[1]
            }
            if (this.activeName == '1') {
                this.getMemberList()
            } else if (this.activeName == '2') {
                this.getRechargeList()
            } else {
                this.getTrainList()
            }
        },
        handleClick(val) {
            this.page = 1;
            this.limit = 10;
            this.list = [];
            this.memberType = '';
            this.rechargeType = '';
            this.trainType = '';
            this.time = '';
            this.started = '';
            this.ended = '';
            this.multipleSelection = []
            if (val.name == '1') {
                this.getMemberList()
            } else if (val.name == '2') {
                this.getRechargeList()
            } else {
                this.getTrainList()
            }
        },
        sizeChangeBtn(val) {
            this.limit = val
            if (this.activeName == '1') {
                this.getMemberList()
            } else if (this.activeName == '2') {
                this.getRechargeList()
            } else {
                this.getTrainList()
            }
        },
        currentChangeBtn(val) {
            this.page = val;
            if (this.activeName == '1') {
                this.getMemberList()
            } else if (this.activeName == '2') {
                this.getRechargeList()
            } else {
                this.getTrainList()
            }
        },
        addCode() {
            if (this.activeName == '1') {
                this.$refs.addRedemptionCode.openAdd()
            } else if (this.activeName == '2') {
                this.$refs.addRedemptionCode.openAddTwo()
            } else {
                this.$refs.addRedemptionCode.openAddThree()
            }
        },
    },

};
</script>

<style scoped lang="scss">
@import "./index.scss";
.height {
    height: calc(100vh - 364px);
}
</style>
