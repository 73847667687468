<template>
    <div class="zc_dialog_box">
        <el-dialog :title="titleText" :visible.sync="dialogAdd" width="622px" @close="close" :close-on-click-modal="false">
            <div class="add_main" v-loading="loading">
                <div class="main_text">
                    <div class="left"><span>*</span>套餐名称</div>
                    <div class="right">
                        <el-input v-model="form.name" maxlength="10" placeholder="请输入套餐名称"></el-input>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span>*</span>现价</div>
                    <div class="right">
                        <el-input v-model="form.current_price" oninput="this.value=this.value.replace(/[^0-9.]/g,'')" placeholder="请输入现价金额"></el-input>
                        <div class="box">元</div>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span>*</span>原价</div>
                    <div class="right">
                        <el-input v-model="form.original_price" oninput="this.value=this.value.replace(/[^0-9.]/g,'')" placeholder="请输入原价金额"></el-input>
                        <div class="box">元</div>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span>*</span>钻石</div>
                    <div class="right">
                        <el-input v-model="form.diamond" oninput="this.value=this.value.replace(/[^0-9.]/g,'')" placeholder="请输入支付钻石数"></el-input>
                        <div class="box">钻</div>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left">优惠额度</div>
                    <div class="right">
                        <el-input v-model="form.youhui" oninput="this.value=this.value.replace(/[^0-9.]/g,'')" placeholder="请输入优惠额度"></el-input>
                        <div class="box">钻</div>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span>*</span>卡类</div>
                    <div class="right w102">
                        <el-select v-model="form.card_type" placeholder="请选择">
                            <el-option v-for="item in cardTypeList" :key="item.value" :label="item.label" :value="item.value+''">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span>*</span>视频制作时长</div>
                    <div class="right">
                        <div class="id-card">
                            <div class="id-card-item">
                                <div class="id-card-tit">分钟</div>
                                <div class="id-card-main">
                                    <el-input v-model="form.rights.video.earn_number" oninput="this.value=this.value.replace(/[^0-9.]/g,'')" placeholder="请输入"></el-input>
                                </div>
                            </div>
                            <div class="id-card-item">
                                <div class="id-card-tit">有效期</div>
                                <div class="id-card-main">
                                    <el-select v-model="form.rights.video.date_valid" placeholder="有效期">
                                        <el-option v-for="item in dateValidList" :key="item.value" :label="item.label" :value="item.value+''">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span>*</span>绘画生成次数</div>
                    <div class="right">
                        <div class="id-card">
                            <div class="id-card-item">
                                <div class="id-card-tit">次数</div>
                                <div class="id-card-main">
                                    <el-input v-model="form.rights.draw.earn_number" oninput="this.value=this.value.replace(/[^0-9.]/g,'')" placeholder="请输入"></el-input>
                                </div>
                            </div>
                            <div class="id-card-item">
                                <div class="id-card-tit">有效期</div>
                                <div class="id-card-main">
                                    <el-select v-model="form.rights.draw.date_valid" placeholder="有效期">
                                        <el-option v-for="item in dateValidList" :key="item.value" :label="item.label" :value="item.value+''">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span>*</span>音频制作时长</div>
                    <div class="right">
                        <div class="id-card">
                            <div class="id-card-item">
                                <div class="id-card-tit">分钟</div>
                                <div class="id-card-main">
                                    <el-input v-model="form.rights.audio.earn_number" oninput="this.value=this.value.replace(/[^0-9.]/g,'')" placeholder="请输入"></el-input>
                                </div>
                            </div>
                            <div class="id-card-item">
                                <div class="id-card-tit">有效期</div>
                                <div class="id-card-main">
                                    <el-select v-model="form.rights.audio.date_valid" placeholder="有效期">
                                        <el-option v-for="item in dateValidList" :key="item.value" :label="item.label" :value="item.value+''">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span>*</span>AI漫画生成次数</div>
                    <div class="right">
                        <div class="id-card">
                            <div class="id-card-item">
                                <div class="id-card-tit">次数</div>
                                <div class="id-card-main">
                                    <el-input v-model="form.rights.cartoon.earn_number" oninput="this.value=this.value.replace(/[^0-9.]/g,'')" placeholder="请输入"></el-input>
                                </div>
                            </div>
                            <div class="id-card-item">
                                <div class="id-card-tit">有效期</div>
                                <div class="id-card-main">
                                    <el-select v-model="form.rights.cartoon.date_valid" placeholder="有效期">
                                        <el-option v-for="item in dateValidList" :key="item.value" :label="item.label" :value="item.value+''">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogAdd = false">取 消</el-button>
                <el-button size="small" type="primary" :loading="btnLoading" @click="confirm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        dateValidList: Array,
        cardTypeList: Array,
    },
    data() {
        return {
            dialogAdd: false,
            btnLoading: false,
            loading: false,
            form: {
                name: "",
                original_price: "",
                current_price: "",
                diamond: '',
                youhui: '',
                card_type: '',
                rights: {
                    video: {
                        earn_number: '',
                        date_valid: ''
                    },
                    draw: {
                        earn_number: '',
                        date_valid: ''
                    },
                    audio: {
                        earn_number: '',
                        date_valid: ''
                    },
                    cartoon: {
                        earn_number: '',
                        date_valid: ''
                    }
                }
            },
            titleText: '新增',
            id: '',
        };
    },
    created() {

    },
    methods: {
        open(val) {
            if (val) {
                this.titleText = '编辑'
                this.id = val.id
                this.form = {
                    name: val.name,
                    original_price: val.original_price,
                    current_price: val.current_price,
                    diamond: val.diamond,
                    youhui: val.youhui == '0' ? '' : val.youhui,
                    card_type: val.card_type + '',
                    rights: {
                        video: {
                            earn_number: val.rights.video.earn_number,
                            date_valid: val.rights.video.date_valid + ''
                        },
                        draw: {
                            earn_number: val.rights.draw.earn_number,
                            date_valid: val.rights.draw.date_valid + ''
                        },
                        audio: {
                            earn_number: val.rights.audio.earn_number,
                            date_valid: val.rights.audio.date_valid + ''
                        },
                        cartoon: {
                            earn_number: val.rights.cartoon.earn_number,
                            date_valid: val.rights.cartoon.date_valid + ''
                        }
                    }
                }
            } else {
                this.titleText = '新增'
            }
            this.dialogAdd = true
        },
        confirm() {
            if (this.form.name == '') {
                this.$errMsg('请输入套餐名称')
                return
            }
            if (this.form.original_price == '') {
                this.$errMsg('请输入原价金额')
                return
            }
            if (this.form.current_price == '') {
                this.$errMsg('请输入现价金额')
                return
            }
            if (this.form.diamond == '') {
                this.$errMsg('请输入钻石数')
                return
            }
            if (this.form.card_type == '') {
                this.$errMsg('请选择卡类')
                return
            }
            if (this.form.rights.video.earn_number == '') {
                this.$errMsg('请输入视频制作时长')
                return
            }
            if (this.form.rights.video.date_valid == '') {
                this.$errMsg('请选择视频制作有效期')
                return
            }
            if (this.form.rights.draw.earn_number == '') {
                this.$errMsg('请输入绘画生成次数')
                return
            }
            if (this.form.rights.draw.date_valid == '') {
                this.$errMsg('请选择绘画生成有效期')
                return
            }
            if (this.form.rights.audio.earn_number == '') {
                this.$errMsg('请输入音频时长')
                return
            }
            if (this.form.rights.audio.date_valid == '') {
                this.$errMsg('请选择音频有效期')
                return
            }
            if (this.form.rights.cartoon.earn_number == '') {
                this.$errMsg('请输入AI漫画次数')
                return
            }
            if (this.form.rights.cartoon.date_valid == '') {
                this.$errMsg('请选择AI漫画有效期')
                return
            }
            if (this.titleText == '新增') {
                // 新增
                this.add()
            } else {
                // 编辑
                this.edit()
            }
        },
        add() {
            this.btnLoading = true
            this.$setupApi.memberAdd(this.form).then(res => {
                this.btnLoading = false
                if (res.code == 1000) {
                    this.dialogAdd = false;
                    this.$succMsg(res.message)
                    this.$emit('getList', '')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        edit() {
            this.$setupApi.memberEdit(this.form, this.id).then(res => {
                this.btnLoading = false
                if (res.code == 1000) {
                    this.dialogAdd = false;
                    this.$succMsg(res.message)
                    this.$emit('getList', '')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        close() {
            this.btnLoading = false;
            this.loading = false;
            this.id = '';
            this.form = {
                name: "",
                original_price: "",
                current_price: "",
                diamond: '',
                youhui: '',
                card_type: '',
                rights: {
                    video: {
                        earn_number: '',
                        date_valid: ''
                    },
                    draw: {
                        earn_number: '',
                        date_valid: ''
                    },
                    audio: {
                        earn_number: '',
                        date_valid: ''
                    },
                    cartoon: {
                        earn_number: '',
                        date_valid: ''
                    }
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
.add_main {
    width: 100%;

    .center {
        align-items: center;
    }
    .main_text {
        width: 100%;
        margin-bottom: 20px;
        display: flex;

        .left {
            width: 120px;
            text-align: right;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 40px;
            margin-right: 20px;

            span {
                padding-right: 5px;
                color: #e00c25;
            }
        }

        .right {
            width: 360px;
            display: flex;
            align-items: center;

            .el-input {
                width: 100%;
            }

            .el-date-editor {
                width: 100%;
            }
            .box {
                width: 60px;
                height: 40px;
                background: #2e4bf2;
                border-radius: 0px 4px 4px 0px;
                margin-left: -2px;
                z-index: 100;
                font-size: 14px;
                color: #ffffff;
                text-align: center;
                line-height: 40px;
            }
        }
        .id-card {
            width: 420px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .id-card-item {
                display: flex;
                align-items: center;
                .id-card-tit {
                    width: 46px;
                    text-align: right;
                    font-size: 14px;
                }
                .id-card-main {
                    margin-left: 10px;
                    width: 102px;
                }
            }
        }
    }
    .right.w102 {
        width: 102px;
    }
}

::v-deep.zc_dialog_box .el-dialog {
    margin-top: 7vh !important;
}
</style>
